import React from 'react';
import { Row, Column, Container } from '../Grid';
import Section from '../Section';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Image from '../Image';
import './styles.scss';

const Subfooter = ({
  footerImage,
  footerText,
  footerSourceOne,
  footerSourceTwo,
  footerSourceThree,
  textRight,
  textRed,
  className,
}) => {
  const getClassnames = () =>
    classNames(
      'subfooter__text',
      className,
      textRight && 'subfooter--text-right',
      textRed && 'subfooter--text-red'
    );

  return (
    <Section className="pb-0 subfooter">
      <Container className="home__stat-container">
        <Row className="subfooter__background">
          <Image filename={footerImage} className="subfooter__image" />
          <div className={getClassnames()}>
            <div className="subfooter__quote">{footerText}</div>
            <div className="subfooter__source">
              <span className="source-name">{footerSourceOne}</span>
              <br />
              {footerSourceTwo}
              <br />
              {footerSourceThree}
            </div>
          </div>
        </Row>
      </Container>
    </Section>
  );
};

Subfooter.defaultProps = {
  textRight: false,
  textRed: false,
  className: null,
};

Subfooter.propTypes = {
  footerImage: PropTypes.string,
  footerText: PropTypes.string,
  footerSource: PropTypes.string,
  textRight: PropTypes.bool,
  textRed: PropTypes.bool,
};

export default Subfooter;
