import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Action from './Action';
import Icon from '../Icon';
import Image from '../Image';
import { Link } from 'gatsby';

import './styles.scss';

const Resource = ({
  unit,
  unitPath,
  tophat,
  tophatClass,
  ribbon,
  ribbonClass,
  icon,
  subject,
  subjectClass,
  title,
  audience,
  duration,
  lab,
  description,
  actions,
  horizontal,
  className,
  alignedButtons,
  withBorder,
  asCard,
  img,
  contentClassOverride,
  titleClass,
}) => {
  const getClassNames = () =>
    classNames(
      'resource',
      horizontal && 'resource--horizontal',
      withBorder && 'resource--bordered',
      className
    );

  const renderDurationLab = () => {
    if (duration && lab) {
      return (
        <div className="resource__duration_lab">
          <div>
            <Icon name="clock" marginRight /> {duration}
          </div>
          <div className="ml-1">
            <Icon name="filledbeaker" marginRight /> {lab}
          </div>
        </div>
      );
    } else if (duration) {
      return (
        <div className="resource__duration">
          <Icon name="clock" marginRight /> {duration}
        </div>
      );
    }

    return null;
  };

  const renderImage = () => {
    let image = (
      <>
        <Image filename={img} className="resource__image" />
        {icon && <Image filename={icon} className="resource__icon" />}
      </>
    );

    return (
      <>
        {tophat && <div className="resource__tophat">{tophat}</div>}
        <div className="resource__image-container">{image}</div>
      </>
    );
  };

  const renderContent = () => (
    <div className={classNames('resource__content', contentClassOverride)}>
      {subject && (
        <div className={classNames('resource__subject', subjectClass)}>
          {subject}
        </div>
      )}
      <h2
        className={classNames(
          'resource__title',
          asCard && 'resource__title--as-card',
          titleClass
        )}
      >
        {title}
      </h2>
      {audience && <div className="resource__audience">{audience}</div>}
      {renderDurationLab()}
      {description && (
        <div
          className={classNames(
            'resource__description',
            asCard && 'resource__description--as-card',
            'mt-1 mb-1'
          )}
          dangerouslySetInnerHTML={{ __html: `${description}` }}
        />
      )}
      {actions && (
        <div
          className={classNames(
            'resource__actions',
            alignedButtons && 'resource__actions--aligned'
          )}
        >
          {actions.map((action, i) => (
            <Action key={i} {...action} />
          ))}
        </div>
      )}
    </div>
  );

  if (horizontal) {
    return (
      <Container>
        <div className={getClassNames()}>
          <Row>
            <Column size={6}>{renderContent()}</Column>
          </Row>
        </div>
      </Container>
    );
  }

  return ribbon ? (
    <div className="resource__with-ribbon">
      <div className={getClassNames()}>{renderContent()}</div>
    </div>
  ) : (
    <div className={getClassNames()}>
      {img ? renderImage() : null}
      {renderContent()}
    </div>
  );
};

Resource.defaultProps = {
  tophat: null,
  subject: null,
  audience: null,
  duration: null,
  description: null,
  actions: null,
  horizontal: false,
  className: null,
  alignedButtons: true,
  withBorder: true,
};

Resource.propTypes = {
  /** The image's file name */
  img: PropTypes.string.isRequired,
  /** The icon's file name */
  icon: PropTypes.string,
  /** Text for the tophat */
  tophat: PropTypes.string,
  /** The subject */
  subject: PropTypes.string,
  /** The title */
  title: PropTypes.string.isRequired,
  /** The audience */
  audience: PropTypes.string,
  /** The duration */
  duration: PropTypes.string,
  /** The description */
  description: PropTypes.node,
  /** The actions */
  actions: PropTypes.arrayOf(PropTypes.object),
  /** Horizontal orientation */
  horizontal: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
  /** Align buttons in multiple cards horizontally */
  alignedButtons: PropTypes.bool,
  /** Surround the card with a border */
  withBorder: PropTypes.bool,
};

export default Resource;
