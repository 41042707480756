import React from 'react';
import Button from '../components/Button';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import OutboundLink from '../components/OutboundLink';
import Section from '../components/Section';
import Subfooter from '../components/Subfooter';
import Video from '../components/Video';
import Resource from '../components/Resource';
import { Link } from 'gatsby';
import bgImage from '../images/CPdetail-image-videostill-bkgd-Kim.jpg';
import '../styles/styles.scss';

const MSCareerProfile = () => (
  <Layout title="Health Equity Careers | Community health Worker">
    <Section className="about-mission">
      <Container>
        <Button
          link
          to="/career-profiles"
          className="individual-profile__return"
        >
          &#60; View All
        </Button>
        <h1 className="h1 text-bold">Health Equity Careers</h1>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container fullWidth>
        <Row>
          <Column>
            <div
              className="career-profile__video-wrapper"
              style={{ backgroundImage: `url('${bgImage}')` }}
            >
              <div className="career-profile__link-container">
                <Link className="career-profile__link" to="/patient-affairs">
                  <Icon name="arrowleft" />
                </Link>
              </div>
              <div className="career-profile__video-container">
                <Video guidSrc="3c93daf0-79ca-4b7f-9430-71b95d27ea22" />
              </div>
              <div className="career-profile__link-container">
                <Link className="career-profile__link" to="/operations-manager">
                  <Icon name="arrowright" />
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0 pb-0">
      <Container>
        <Row>
          <Column size={8}>
            <h2 className="h2 mt-1">Community health Worker</h2>
            <p>
              Learn more about this career by exploring the in-depth career fact
              sheet and engage students with hands-on activities for any
              environment.
            </p>
            {/* Quick links */}
            <div className="individual-profile__links">
              Quick Links:&nbsp;
              <Button link to="#about">
                About
              </Button>
              |
              <Button link to="#evaluate">
                Evaluate Your Interest
              </Button>
              |
              <Button link to="#activate">
                Activate Your Interest
              </Button>
            </div>

            <div>
              <Resource
                title="About"
                description="A Community Health Worker immerses themself in a community to understand its needs and challenges. Joaquin acts as a liaison between members of the community and the public health departments, companies, and hospitals providing them with services. An empathetic communicator, he has to engage with and listen to the people he works with to find out what they need and guide them into the programs that will better their lives."
                actions={[
                  {
                    label: 'Career Profile',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-Career Profile-CommunityHealthWorker-V1.0.pdf',
                        type: 'download',
                        title: 'Community Health Worker Career Profile-English',
                      },
                    ],
                  },
                ]}
              />
            </div>
            <div className="individual-profile__evaluate mt-1">
              <h3 className="h3" id="evaluate">
                Evaluate Your Interest
              </h3>
              <p>
                Find out more about this career and see how it aligns with
                student interests.
              </p>
              <ul>
                <li>
                  Actively engage with the community to understand its unique
                  needs.
                </li>
                <li>
                  Effectively communicate with numerous stakeholders with
                  different backgrounds.
                </li>
                <li>
                  Understand the programs and services available to a community,
                  as well as how to access them for people.
                </li>
                <li>
                  Possess the empathy to understand not just what help people
                  need, but why they need it.
                </li>
              </ul>
            </div>
            <div className="individual-profile__activate">
              <Resource
                title="Activate Your Interests"
                description="Bring this career to life with a classroom activity that
              empowers students to identify health inequities in their own
              communities and promote health equity awareness. This activity
              is designed as a companion to the video profile."
                actions={[
                  {
                    label: 'Student Activation',
                    type: 'dropdown',
                    actions: [
                      {
                        label: 'English',
                        path:
                          '/pdfs/career-profiles/Takeda-BetterHealth-StudentActivation-CommunityHealthWorker-V1.0.pdf',
                        type: 'download',
                        title:
                          'Community Health Worker Student Activation-English',
                      },
                    ],
                  },
                ]}
              />
            </div>
          </Column>
          {/* Side column */}
          <Column size={4} className="pl-3 pt-4 pr-3">
            <Image
              filename="global-image-headshot-Joaquin@2x.png"
              className="careerProfiles__headshot"
            />
            <div className="individual-profile__holder">
              <div className="individual-profile__quote">
                “We’re working with folks looking to better their lives.”
                <div className="mt-1">Joaquin Rodriguez</div>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section className="pt-0">
      <Container>
        <Row style={{ justifyContent: 'center' }}>
          <Column size={8}>
            <Image filename="global-languages-promo-box@2x.png" />
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={12}>
            <h3 className="h3">Additional Careers to Explore</h3>
            <p>
              Discover the many exciting STEM careers that help support health
              equity globally. Learn what inspires these diverse individuals by
              exploring the below Career Profiles.
            </p>
          </Column>
        </Row>
        <Row className="mt-2">
          <Column size={6} className="pr-1">
            <OutboundLink to="/patient-affairs">
              <Image filename="CPdetail-image-careers-vp.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Vice President and Head of the Center
              <br />
              for Health Equity and Patient Affairs
            </h3>
            <p>
              Find out what is needed to oversee a Center of Excellence focused
              on addressing health inequities from a doctor who is driven by a
              lifelong passion to provide care for others.
            </p>
            <Button to="/patient-affairs" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
          <Column size={6} className="pl-1">
            <OutboundLink to="/pharmaceutical-sciences">
              <Image filename="CPdetail-image-careers-AssocDir.jpg" />
            </OutboundLink>
            <h3 className="h3">
              Associate Director of Pharmaceutical Sciences
            </h3>
            <p>
              Learn how advanced technologies like robotics and artifical
              intelligence are being incorporated into the process of providing
              better healthcare to patients.
            </p>
            <Button to="/pharmaceutical-sciences" className="button--link">
              Learn more <Icon name="arrowright" marginLeft />
            </Button>
          </Column>
        </Row>
      </Container>
    </Section>

    <Subfooter
      className="subfooter__quote-careers-no-quotes"
      textRight
      textRed
      footerImage="CPdetail-kim-image-QuoteBox.jpg"
      footerText="Delivering high-quality, innovative medicines and products, to patients when and where they need them."
    />
  </Layout>
);

export default MSCareerProfile;
